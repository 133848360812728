exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-celunk-index-js": () => import("./../../../src/pages/celunk/index.js" /* webpackChunkName: "component---src-pages-celunk-index-js" */),
  "component---src-pages-csapatunk-index-js": () => import("./../../../src/pages/csapatunk/index.js" /* webpackChunkName: "component---src-pages-csapatunk-index-js" */),
  "component---src-pages-csapatunk-rolunk-items-js": () => import("./../../../src/pages/csapatunk/RolunkItems.js" /* webpackChunkName: "component---src-pages-csapatunk-rolunk-items-js" */),
  "component---src-pages-csapatunk-rolunk-motivation-js": () => import("./../../../src/pages/csapatunk/RolunkMotivation.js" /* webpackChunkName: "component---src-pages-csapatunk-rolunk-motivation-js" */),
  "component---src-pages-csapatunk-rolunk-persons-js": () => import("./../../../src/pages/csapatunk/RolunkPersons.js" /* webpackChunkName: "component---src-pages-csapatunk-rolunk-persons-js" */),
  "component---src-pages-csapatunk-rolunk-slider-js": () => import("./../../../src/pages/csapatunk/RolunkSlider.js" /* webpackChunkName: "component---src-pages-csapatunk-rolunk-slider-js" */),
  "component---src-pages-eredmenyek-index-js": () => import("./../../../src/pages/eredmenyek/index.js" /* webpackChunkName: "component---src-pages-eredmenyek-index-js" */),
  "component---src-pages-fooldal-hirek-slider-items-js": () => import("./../../../src/pages/Fooldal/HirekSliderItems.js" /* webpackChunkName: "component---src-pages-fooldal-hirek-slider-items-js" */),
  "component---src-pages-fooldal-hirek-slider-js": () => import("./../../../src/pages/Fooldal/HirekSlider.js" /* webpackChunkName: "component---src-pages-fooldal-hirek-slider-js" */),
  "component---src-pages-fooldal-main-container-1-js": () => import("./../../../src/pages/Fooldal/MainContainer1.js" /* webpackChunkName: "component---src-pages-fooldal-main-container-1-js" */),
  "component---src-pages-fooldal-main-container-2-js": () => import("./../../../src/pages/Fooldal/MainContainer2.js" /* webpackChunkName: "component---src-pages-fooldal-main-container-2-js" */),
  "component---src-pages-fooldal-partnerek-js": () => import("./../../../src/pages/Fooldal/Partnerek.js" /* webpackChunkName: "component---src-pages-fooldal-partnerek-js" */),
  "component---src-pages-fooldal-right-side-js": () => import("./../../../src/pages/Fooldal/RightSide.js" /* webpackChunkName: "component---src-pages-fooldal-right-side-js" */),
  "component---src-pages-fooldal-slider-js": () => import("./../../../src/pages/Fooldal/Slider.js" /* webpackChunkName: "component---src-pages-fooldal-slider-js" */),
  "component---src-pages-fooldal-tamogatok-js": () => import("./../../../src/pages/Fooldal/Tamogatok.js" /* webpackChunkName: "component---src-pages-fooldal-tamogatok-js" */),
  "component---src-pages-fooldal-video-youtube-js": () => import("./../../../src/pages/Fooldal/VideoYoutube.js" /* webpackChunkName: "component---src-pages-fooldal-video-youtube-js" */),
  "component---src-pages-fooldal-yellow-js": () => import("./../../../src/pages/Fooldal/Yellow.js" /* webpackChunkName: "component---src-pages-fooldal-yellow-js" */),
  "component---src-pages-fooldal-youtube-js": () => import("./../../../src/pages/Fooldal/Youtube.js" /* webpackChunkName: "component---src-pages-fooldal-youtube-js" */),
  "component---src-pages-hirek-1-js": () => import("./../../../src/pages/hirek/1.js" /* webpackChunkName: "component---src-pages-hirek-1-js" */),
  "component---src-pages-hirek-2-js": () => import("./../../../src/pages/hirek/2.js" /* webpackChunkName: "component---src-pages-hirek-2-js" */),
  "component---src-pages-hirek-3-js": () => import("./../../../src/pages/hirek/3.js" /* webpackChunkName: "component---src-pages-hirek-3-js" */),
  "component---src-pages-hirek-biologiai-sokfeleseg-napja-a-fuveszkertben-js": () => import("./../../../src/pages/hirek/biologiai_sokfeleseg_napja_a_fuveszkertben.js" /* webpackChunkName: "component---src-pages-hirek-biologiai-sokfeleseg-napja-a-fuveszkertben-js" */),
  "component---src-pages-hirek-foldi-javak-asztalunkon-mi-is-ott-voltunk-js": () => import("./../../../src/pages/hirek/foldi_javak_asztalunkon_mi_is_ott_voltunk.js" /* webpackChunkName: "component---src-pages-hirek-foldi-javak-asztalunkon-mi-is-ott-voltunk-js" */),
  "component---src-pages-hirek-interju-az-m-5-musoran-js": () => import("./../../../src/pages/hirek/interju_az_m5_musoran.js" /* webpackChunkName: "component---src-pages-hirek-interju-az-m-5-musoran-js" */),
  "component---src-pages-hirek-irt-rounk-a-national-geographic-js": () => import("./../../../src/pages/hirek/irt_rounk_a_national_geographic.js" /* webpackChunkName: "component---src-pages-hirek-irt-rounk-a-national-geographic-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-hirek-kistavakrol-a-klub-js": () => import("./../../../src/pages/hirek/kistavakrol_a_klub.js" /* webpackChunkName: "component---src-pages-hirek-kistavakrol-a-klub-js" */),
  "component---src-pages-hirek-lezarult-a-mintavetel-js": () => import("./../../../src/pages/hirek/lezarult_a_mintavetel.js" /* webpackChunkName: "component---src-pages-hirek-lezarult-a-mintavetel-js" */),
  "component---src-pages-hirek-magyarorszag-legnagyobb-kisto-felmerese-js": () => import("./../../../src/pages/hirek/magyarorszag_legnagyobb_kisto_felmerese.js" /* webpackChunkName: "component---src-pages-hirek-magyarorszag-legnagyobb-kisto-felmerese-js" */),
  "component---src-pages-hirek-megismerhettek-munkankat-a-24-hu-olvasoi-js": () => import("./../../../src/pages/hirek/megismerhettek_munkankat_a_24.hu_olvasoi.js" /* webpackChunkName: "component---src-pages-hirek-megismerhettek-munkankat-a-24-hu-olvasoi-js" */),
  "component---src-pages-hirek-mintakuldes-js": () => import("./../../../src/pages/hirek/mintakuldes.js" /* webpackChunkName: "component---src-pages-hirek-mintakuldes-js" */),
  "component---src-pages-hirek-mintavetel-a-fovarosi-allat-es-novenykert-kistavaibol-js": () => import("./../../../src/pages/hirek/mintavetel_a_fovarosi_allat_es_novenykert_kistavaibol.js" /* webpackChunkName: "component---src-pages-hirek-mintavetel-a-fovarosi-allat-es-novenykert-kistavaibol-js" */),
  "component---src-pages-hirek-mypond-a-kutatok-ejszakajan-js": () => import("./../../../src/pages/hirek/mypond_a_kutatok_ejszakajan.js" /* webpackChunkName: "component---src-pages-hirek-mypond-a-kutatok-ejszakajan-js" */),
  "component---src-pages-hirek-mypond-kutatok-a-tilos-radioban-js": () => import("./../../../src/pages/hirek/mypond_kutatok_a_tilos_radioban.js" /* webpackChunkName: "component---src-pages-hirek-mypond-kutatok-a-tilos-radioban-js" */),
  "component---src-pages-hirek-olvasd-el-a-qubit-hu-rolunk-irt-cikket-js": () => import("./../../../src/pages/hirek/olvasd_el_a_qubit.hu_rolunk_irt_cikket.js" /* webpackChunkName: "component---src-pages-hirek-olvasd-el-a-qubit-hu-rolunk-irt-cikket-js" */),
  "component---src-pages-hirek-vigyazz-kesz-mintazz-js": () => import("./../../../src/pages/hirek/vigyazz_kesz_mintazz.js" /* webpackChunkName: "component---src-pages-hirek-vigyazz-kesz-mintazz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-index-js": () => import("./../../../src/pages/kapcsolat/index.js" /* webpackChunkName: "component---src-pages-kapcsolat-index-js" */),
  "component---src-pages-kerdoiv-index-js": () => import("./../../../src/pages/kerdoiv/index.js" /* webpackChunkName: "component---src-pages-kerdoiv-index-js" */),
  "component---src-pages-kisokos-index-js": () => import("./../../../src/pages/kisokos/index.js" /* webpackChunkName: "component---src-pages-kisokos-index-js" */),
  "component---src-pages-kisokos-kisokos-items-js": () => import("./../../../src/pages/kisokos/KisokosItems.js" /* webpackChunkName: "component---src-pages-kisokos-kisokos-items-js" */),
  "component---src-pages-kisokos-kistavak-kialtvany-js": () => import("./../../../src/pages/kisokos/kistavak_kialtvany.js" /* webpackChunkName: "component---src-pages-kisokos-kistavak-kialtvany-js" */),
  "component---src-pages-kisokos-kistavak-nagy-jelentoseggel-js": () => import("./../../../src/pages/kisokos/kistavak_nagy_jelentoseggel.js" /* webpackChunkName: "component---src-pages-kisokos-kistavak-nagy-jelentoseggel-js" */),
  "component---src-pages-kisokos-tippek-js": () => import("./../../../src/pages/kisokos/tippek.js" /* webpackChunkName: "component---src-pages-kisokos-tippek-js" */),
  "component---src-pages-kisokos-tudod-mi-az-a-citizen-science-citizen-science-content-js": () => import("./../../../src/pages/kisokos/tudod_mi_az_a_citizen_science/CitizenScienceContent.js" /* webpackChunkName: "component---src-pages-kisokos-tudod-mi-az-a-citizen-science-citizen-science-content-js" */),
  "component---src-pages-kisokos-tudod-mi-az-a-citizen-science-index-js": () => import("./../../../src/pages/kisokos/tudod_mi_az_a_citizen_science/index.js" /* webpackChunkName: "component---src-pages-kisokos-tudod-mi-az-a-citizen-science-index-js" */),
  "component---src-pages-kisokos-zold-varangy-js": () => import("./../../../src/pages/kisokos/zold_varangy.js" /* webpackChunkName: "component---src-pages-kisokos-zold-varangy-js" */),
  "component---src-pages-kisokos-zold-varangy-slider-js": () => import("./../../../src/pages/kisokos/ZoldVarangySlider.js" /* webpackChunkName: "component---src-pages-kisokos-zold-varangy-slider-js" */),
  "component---src-pages-mintavetel-index-js": () => import("./../../../src/pages/mintavetel/index.js" /* webpackChunkName: "component---src-pages-mintavetel-index-js" */)
}

